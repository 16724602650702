export default {
  translation: {
    name: 'EN',
    currency: ' ₴',
    charSet: 'utf-8',
    status: 'We are temporarily not accepting orders. We apologize to you',
    seo: {
      about: {
        title: '🍕 Family pizzeria | Bella Mozzarella',
        keywords: '',
        description:
          'Family pizzeria Bella Mozzarella is the most delicious Italian pizza in Kharkiv. Our address: Kharkov, Metrostroiteley street 17-A. Come and make sure.',
      },
      bonuses: {
        title: ' Bonus program | Bella Mozzarella',
        keywords: '',
        description:
          'Bonus program Bella Mozzarella. Round-the-clock pizza delivery in Kharkov.',
      },
      delivery: {
        title:
          'Food delivery Kharkov around the clock to your home or office | Bella Mozzarella',
        keywords:
          'food delivery, food delivery kharkiv, 24/7 food delivery, 24/7 food delivery kharkiv, 24/7 food delivery in kharkiv, food delivery in kharkiv',
        description:
          'Food delivery Kharkov around the clock. We deliver food to your home in Kharkov 24/7. Free food delivery for orders over 249 UAH. Call - ☎ 098-43-21-000!',
      },
      terms: {
        title: 'Terms of use | Bella Mozzarella',
        keywords: '',
        description: '',
      },
      main: {
        title:
          'Pizza Kharkiv | Pizza delivery Kharkov around the clock | Bella Mozzarella',
        keywords:
          'pizza kharkiv, pizza delivery kharkiv, order pizza kharkiv, food delivery, food delivery kharkiv, order pizza, pizza delivery, round the clock food delivery, round the clock food delivery Kharkiv',
        description:
          'Pizza Kharkiv. Pizza delivery Kharkov around the clock to your home or office. ✅️ Order Kharkiv pizza with delivery by phone ☎ +38 (098) 43-21-000. ❗ Regular promotions and bonus program. European quality and service at affordable prices!!! Pizza delivery up to 59 minutes. Food delivery Kharkiv is delicious!',
        page: 'https://bellamozzarella.com.ua/en',
      },
      half: {
        title: '🍕 Pizza from halves in Kharkov | Bella Mozzarella',
        keywords: '',
        description:
          'Pizza from 119 hrn. Fast and free pizza delivery to home and office.',
      },
    },
    components: {
      apps: {
        title: 'Bella Mozzarella',
        text: 'Download app',
      },
      header: {
        bonus: 'Bonus program',
        delivery: 'Shipping and Payment',
        city: 'Kharkiv',
      },
      badge: {
        new: 'NEW',
      },
      navigation: {
        pizzas: 'Pizzas',
        combos: 'Combos',
        snacks: 'Snacks',
        desserts: 'Desserts',
        drinks: 'Drinks',
        other: 'Other',
        promotions: 'Promotions',
        contacts: 'Contact',
        about: 'About us',
      },
      category: {
        new: 'New!',
      },
      nutritional: {
        nutriValue: 'Nutritional value per 100 g',
        energy: 'Energy value',
        squirrels: 'Squirrels',
        fats: 'Fats',
        carbohydrates: 'Carbohydrates',
        calories: 'kcal',
        weight: 'Weight ',
        gram: 'g',
      },
      cart: {
        button: 'Cart',
      },
      footer: {
        joinTheTeam: 'Join to team Bella Mozzarella',
        joinTheTeamButton: 'Fill in the form',
        title1: 'Information',
        f1text1: 'Sells',
        f1text2: 'Bonus program',
        f1text3: 'Shipping and Payment',
        title2: 'Contacts',
        f2text1: '+38 (093) 43 21 000',
        f2text2: '+38 (097) 43 21 000',
        f2text3: '+38 (099) 43 21 000',
        f2text4: 'bellamozzarella.com.ua@gmail.com',
        title3: 'Take away',
        f3text1: 'Family Pizzeria',
        f3text2: 'User Agreement',
        f3text3: 'Kharkiv, Center, m. University',
        f3text4: 'Sumskaya street, 73',
        f3text5: 'Opening hours: 09:00 - 22:00',
        f3text6: 'Petro Grihorenko Avenue, 18/1',
        f3text7: 'Opening hours: 09:00 - 22:00',
        f3text8: 'Phone for booking: +380 (66) 765 38 36',
        copyright1: 'Bella Mozzarella',
        copyright2: '© 2020 - 2024',
      },
      purchase: {
        text: 'Product added to cart',
      },
    },
    pages: {
      main: {
        components: {
          categories: {
            new: 'New',
            select: 'Select',
            add: 'Select',
            craft: 'Collect',
            title: 'Half pizza',
            description: 'Assemble your pizza with two different flavors',
          },
          combo: {
            title: 'Combo',
            leftTitle: 'Choose item',
            subtitle: 'Combine',
          },
          stories: {
            link: 'Order',
          },
          info: {
            title: 'Pizza in Kharkov.',
            subTitle1: 'Pizza delivery in Kharkov.',
            subTitle2: 'How to order pizza in Kharkov?',
            subTitle3: 'Pizza with cheese side in Kharkov.',
            subTitle4: 'Food delivery in Kharkov.',
            textBolt1: 'Advantages of our pizza delivery:',
            textBolt2: 'Our delivery phone numbers:',
            text1:
              'This Pizza in Kharkov will delight you with a large amount of toppings and a huge layer of cheese on the top. In the modern world with a crazy rhythm of life, pizza delivery in Kharkov has become very popular. The family pizzeria Bella Mozzarella offers 24-hours pizza delivery in Kharkov. This 24-hours pizza delivery in Kharkov works all year round, at any time of the day or night, in any weather. We will deliver your favorite pizza anywhere in the city. You will receive a prepared dish, chosen to your taste, quickly, during 59 minutes. Our pizzas are prepared by specially trained chefs and we use only fresh ingredients, old recipes and the latest cooking technologies.',
            text2:
              'Pizza delivery in Kharkov from Bella Mozzarella offers more than 15 types of pizza. You can choose pizza:',
            text3:
              'Delivery in Kharkov is carried out by our couriers with special thermal bags that keep the heat of the cooked pizza. You can always enjoy your favorite hot pizza.',
            text4:
              'Ordering pizza Kharkov is very simple, fast and convenient. Our pizza is prepared on very thin dough; it is so tasty that even a piece of this creation is eaten! It is prepared by our chef from fresh, newly purchased products, which are carefully selected. Having ordered pizza in Kharkov at Bella Mozzarella, you will no longer want to exchange for less. To do this, you do not need to have a good memory, remembering many different numbers and you do not need to be a cool programmer to order dishes for different tastes. You just need to call the operator or place an order on the site in just a couple of clicks. 24-hours food delivery in Kharkov from Bella Mozzarella will pleasantly surprise you. We are waiting for you. Each client is unique and important to us. We are always glad to see you. By ordering 24-hours food delivery in Kharkov at Bella Mozzarella, you will receive your favorite, exceptionally high-quality dish.',
            text5:
              'More recently, we have begun to delight our guests with new pizzas, adding the ability to order pizza with a cheese board to the menu. Philadelphia cream cheese topping in board of the pizza adds more tenderness to this dish. We also have a board with Philadelphia cream cheese and ham. When you order pizza with any of the boards - our courier will bring you an octagonal pizza. Octagonal pizza from Bella Mozzarella is the first pizza in Kharkov made in such a unique design, which we are sure you will like.',
            text6:
              'Bella Mozzarella is not only a food delivery service in Kharkov, but also a family pizzeria. Bella Mozzarella is a place where your soul and body rest. The place, where you can relax alone, with family, with friends or with colleagues. Here you will find Italian dishes that will not leave anyone indifferent. With us you can sit not only inside the restaurant, but also on the summer terrace. Pizza is the most popular here. This dish, beloved by the locals, has come into common use. No one corporate event is complete without pizza. In Bella Mozzarella you can not only sit and relax in the institution itself, but also you can organize a corporate party or celebrate absolutely any holiday. 24-hours pizza delivery in Kharkov works quickly, reliably and efficiently, so you can get not only taste pleasure, but also contemplate the pristine beauty of the dish itself. Bella Mozzarella is not just a family pizzeria, it is the place where you are always welcome and we are always waiting for you. You will be met by polite, hospitable staff, cozy atmosphere, delicious food, quality service and complete relaxation.',
            text7:
              'Food delivery in Kharkov from Bella Mozzarella will provide you with comfort, joy, a sense of celebration, will delight you with fast delivery, which will allow you to always be in good shape and in a good mood.',
            pizzaList1: 'with chicken fillet;',
            pizzaList2: 'with mushrooms and chicken fillet;',
            pizzaList3: 'with veal;',
            pizzaList4: 'with hunting sausages;',
            pizzaList5: 'with ham;',
            pizzaList6: 'with seafood;',
            pizzaList7: 'with different types of cheeses.',
            shippingBenefits1: '24-hours food delivery in Kharkov;',
            shippingBenefits2: 'food delivery in Kharkov - 59 minutes;',
            shippingBenefits3:
              'convenient form of payment (cash, by terminal at the courier, Liqpay on the website, non-cash in the mobile application);',
            shippingBenefits4: 'convenient mobile application;',
            shippingBenefits5: 'regular promotions;',
            shippingBenefits6: 'Birthday discounts.',
            phone1: '+38 (093) 4321000',
            phone2: '+38 (098) 4321000',
            phone3: '+38 (099) 4321000',
          },
        },
      },
      dish: {
        sizeSmall: 'small',
        sizeMedium: 'medium',
        sizeBig: 'big',
        liters: 'ml',
        cm: 'cm',
        grams: ' g',
        ingredientsDisplay: 'Delete ingrediens',
        side: 'Pizza side',
        classicSide: 'Classic',
        cheeseSide: 'Сheesy',
        baconSide: 'Cheese & Bacon',
        add: 'Add to pizza',
        addToCart: 'Add to cart',
      },
      combo: {
        change: 'Change',
        board: 'Board: ',
        choseCombo: 'Choose combo',
        addToCart: 'Add to cart',
      },
      half: {
        choosePizzas: 'Choose pizzas for the left and right halves',
        addToCart: 'Combine the halves',
        choose: 'Choose',
        title: 'Choose a pizza halves',
        left: 'the left side',
        right: 'the right side',
        weight: 'Weight ',
        gram: ' g',
      },
      cart: {
        components: {
          list: {
            subTitle: 'items per',
            board: 'Board: ',
            additiveTitle: 'Add to order?',
          },
          bonus: {
            name: 'Get information about my bonuses',
            enterPhone: 'Enter Phone',
            title: 'Use bonuses',
            subTitle: 'You have ',
            text1: '(No more ',
            text2: ' % of order)',
          },
          promo: {
            title: 'Get discount',
            placeholder: 'Enter promocode',
            text1: 'The promocode is valid for a minimum order of ',
          },
          delivery: {
            title: 'Delivery and Payment',
            home: 'Delivery',
            cafe: 'Pickup',
            delivery: 'Delivery',
            pickup: 'Pickup',
            name: 'Enter Name*',
            phone: 'Enter Phone*',
            address: 'Enter Address*',
            now: 'Delivery for now',
            onTime: 'Delivery on time',
            time: 'Time',
            comment: 'Enter comment',
            meals: 'Quantity cultury to order',
            nomeals: "Don't add a cultury to order",
            required: 'Fields Name, phone, address, are required',
            address1: 'Sumskaya Street, 73',
            address2: 'Petro Grihorenko Avenue, 18/1',
          },
          payment: {
            method: 'Choose a payment method',
            paymentByCash: 'Cash',
            paymentByCart: 'Payment by POS',
            paymentByOnline: 'Payment online',
          },
          liqpay: {
            goToPay: 'Go to Pay',
          },
        },
        title: 'Shopping Cart',
        delivery: 'Delivery',
        freeDelivery: 'Free delivery from ',
        discount: 'Discount: ',
        total: 'Total: ',
        checkoutButton: 'Checkout for ',
        payButton: 'Pay ',
        emptyText: 'Your shopping cart is empty',
        doneTitle: 'Success',
        doneText: 'Your order is accepted',
        doneText2: 'Order number - ',
        donePaymentText:
          'Your order is accepted! Now you can proceed to payment.',
      },
      terms: {
        title: 'Public offer contract',
      },
    },
  },
};
