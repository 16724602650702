import React from 'react';
import {Helmet} from 'react-helmet';
import {withTranslation} from 'react-i18next';

import {currentLang} from '../../helpers/language';
import styles from './styles.module.scss';

const Seo = ({t, path, title, keywords, description, url, id}) => {
  const lang = currentLang();
  const href = window.location.href;
  if (path === 'dish' || path === 'combo' || path === 'half') {
    return (
      <Helmet className={styles.container}>
        <meta charSet={t(`charSet`)} />
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <link
          rel="canonical"
          href={`${window.location.origin}/${lang}/${path}/${id}`}
        />
      </Helmet>
    );
  }

  if (path === 'category') {
    let validURL = href.slice(0, href.indexOf(path));
    validURL = `${validURL}${path}/${url}`;
    return (
      <Helmet className={styles.container}>
        <meta charSet={t(`charSet`)} />
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <link rel="canonical" href={validURL} />
      </Helmet>
    );
  }

  if (path === 'article') {
    let validURL = href.slice(0, href.indexOf(path));
    validURL = `${validURL}${path}/${url}`;
    return (
      <Helmet className={styles.container}>
        <meta charSet={t(`charSet`)} />
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <link rel="canonical" href={validURL} />
      </Helmet>
    );
  }

  return (
    <Helmet className={styles.container}>
      <meta charSet={t(`charSet`)} />
      <title>{t(`seo.${path}.title`)}</title>
      <link rel="canonical" href={t(`seo.${path}.page`)} />
      <meta name="keywords" content={t(`seo.${path}.keywords`)} />
      <meta name="description" content={t(`seo.${path}.description`)} />
    </Helmet>
  );
};

export default withTranslation()(Seo);
