export default {
  translation: {
    name: 'RU',
    currency: ' ₴',
    charSet: 'utf-8',
    status: 'Мы временно не принимаем заказы. Приносим вам свои извинения',
    seo: {
      about: {
        title: '🍕 Семейная пиццерия | Bella Mozzarella',
        keywords: '',
        description:
          'Семейная пиццерия Bella Mozzarella – это вкуснейшая итальянская пицца в Харькове. Наш адрес: Харьков, улица Метростроителей 17-А. Приходи и убедись.',
      },
      bonuses: {
        title: 'Бонусная программа | Bella Mozzarella',
        keywords: '',
        description:
          'Бонусная программа Bella Mozzarella. Круглосуточная доставка пиццы в Харькове.',
      },
      delivery: {
        title:
          'Доставка еды Харьков круглосуточно на дом или в офис| Bella Mozzarella',
        keywords:
          'доставка еды, доставка еды харьков, круглосуточная доставка еды, круглосуточная доставка еды харьков, круглосуточная доставка еды в харькове, доставка еды в харькове',
        description:
          'Доставка еды Харьков круглосуточно. Выполняем доставку еды на дом в Харькове 24/7. Бесплатная доставка еды при заказе от 249 грн. Звони - ☎ 098-43-21-000!',
      },
      terms: {
        title: 'Пользовательское соглашение | Bella Mozzarella',
        keywords: '',
        description: '',
      },
      main: {
        title:
          'Пицца Харьков | Доставка пиццы Харьков круглосуточно |Bella Mozzarella',
        keywords:
          'пицца харьков, доставка пиццы харьков, заказать пиццу харьков, доставка еды, доставка еды харьков, заказать пиццу, доставка пиццы, круглосуточная доставка еды, круглосуточная доставка еды Харьков',
        description:
          'Пицца Харьков. Доставка пиццы Харьков круглосуточно на дом или в офис. ✅️ Заказать пиццу Харьков с доставкой по телефону ☎ +38 (098) 43-21-000. ❗ Постоянные акции и бонусная программа. Европейское качество и сервис по доступным ценам!!! Доставка пиццы до 59 минут. Доставка еды Харьков - это вкусно!',
        page: 'https://bellamozzarella.com.ua/ru',
      },
      half: {
        title: '🍕 Пицца из половинок в Харькове | Bella Mozzarella',
        keywords: '',
        description:
          'Пицца от 119 гривен. Быстрая и бесплатная доставка пиццы домой и в офис.',
      },
    },
    components: {
      apps: {
        title: 'Bella Mozzarella',
        text: 'Скачать приложение',
      },
      header: {
        bonus: 'Бонусная программа',
        delivery: 'Доставка и оплата',
        city: 'Харьков',
      },
      badge: {
        new: 'NEW',
      },
      navigation: {
        pizzas: 'Пицца',
        combos: 'Комбо',
        snacks: 'Закуски',
        desserts: 'Десерты',
        drinks: 'Напитки',
        other: 'Другое',
        promotions: 'Акции',
        contacts: 'Контакты',
        about: 'О нас',
      },
      category: {
        new: 'Новинка',
      },
      nutritional: {
        nutriValue: 'Пищевая ценность на 100 г',
        energy: 'Энерг. ценность',
        squirrels: 'Белки',
        fats: 'Жиры',
        carbohydrates: 'Углеводы',
        calories: 'ккал',
        weight: 'Вес ',
        gram: 'г',
      },
      cart: {
        button: 'Корзина',
      },
      footer: {
        joinTheTeam: 'Присоединиться к команде Bella Mozzarella',
        joinTheTeamButton: 'Заполнить анкету',
        title1: 'Информация',
        f1text1: 'Акции',
        f1text2: 'Бонусная программа',
        f1text3: 'Доставка и оплата',
        title2: 'Контакты',
        f2text1: '+38 (093) 43 21 000',
        f2text2: '+38 (098) 43 21 000',
        f2text3: '+38 (099) 43 21 000',
        f2text4: 'bellamozzarella.com.ua@gmail.com',
        title3: 'Самовывоз',
        f3text1: 'Семейная пиццерия',
        f3text2: 'Пользовательское соглашение',
        f3text3: 'Харьков, Центр, м. Университет',
        f3text4: 'улица Сумская, 73',
        f3text5: 'Время работы: 09:00 - 22:00',
        f3text6: 'Проспект Петра Григоренко, 18/1',
        f3text7: 'Время работы: 09:00 - 22:00',
        f3text8: 'Телефон для бронирования: +380 (66) 765 38 36',
        copyright1: 'Bella Mozzarella',
        copyright2: '© 2020 - 2024',
      },
      purchase: {
        text: 'Товар добавлен в корзину',
      },
    },
    pages: {
      main: {
        components: {
          categories: {
            new: 'Новинка',
            select: 'Выбрать',
            add: 'Выбрать',
            craft: 'Собрать',
            title: 'Пицца из половинок',
            description: 'Соберите свою пиццу с двумя разными вкусами',
          },
          combo: {
            title: 'Комбо',
            subtitle: 'Комбинируй',
          },
          stories: {
            link: 'Заказать',
          },
          info: {
            title: 'Пицца Харьков.',
            subTitle1: 'Доставка пиццы Харьков.',
            subTitle2: 'Заказать пиццу Харьков.',
            subTitle3: 'Пицца с сырными бортиками Харьков.',
            subTitle4: 'Доставка еды Харьков.',
            textBolt1: 'Преимущества нашей доставки пиццы:',
            textBolt2: 'Наши телефоны доставки:',
            text1:
              'Пицца Харьков порадует Вас большим количеством начинки и огромнейшим слоем сыра сверху. В современном мире с безумным ритмом жизни доставка пиццы в Харькове стала пользоваться огромной популярностью. В семейной пиццерии Bella Mozzarella работает круглосуточная доставка пиццы в Харькове. Круглосуточная доставка пиццы в Харькове работает круглогодично, в любое время дня и ночи, в любую погоду. Мы доставим Вашу любимую пиццу в любую точку города. Вы получите готовое, выбранное на свой вкус блюдо быстро, в течении 59 минут. Наши пиццы готовятся специально обученными поварами, только из свежих продуктов, используя старинные рецепты и новейшие технологи приготовления.',
            text2:
              'Доставка пиццы Харьков от Bella Mozzarella предлагает более 15 видов пиццы. Вы можете выбрать пиццу:',
            text3:
              'Доставка в Харькове осуществляется нашими курьерами специальными термосумками, которые сохраняют тепло приготовленной пиццы. Вы сможете всегда насладиться Вашей любимой горячей пиццей.',
            text4:
              'Заказать пиццу Харьков очень просто, быстро, удобно. Наша пицца готовиться на очень тоненьком тесте, она настолько вкусная, что съедается даже краюшек этого творения. Готовит ее наш шеф-повар из свежих, только что купленных продуктов, тщательным образом отобранным. Заказав пиццу в Харькове в Bella Mozzarella, Вы больше не захотите размениваться на меньшее. Для этого не нужно обладать хорошей памятью, запоминая много разных номеров, не нужно быть крутым программистом, при заказе блюд на разный вкус. Нужно просто позвонить оператору либо оформить заказ на сайте всего в пару кликов. Круглосуточная доставка еды в Харькове от Bella Mozzarella приятно удивит Вас. Мы ждем именно Вас. Каждый клиент для нас уникален и важен. Мы рады Вам всегда. Заказав круглосуточную доставку еды в Харькове в Bella Mozzarella, Вы получите любимое, исключительно высококачественное блюдо.',
            text5:
              'Совсем недавно мы начали радовать своих гостей новыми пиццами, добавив в меню возможность заказать пиццу с сырным бортиком. Пицца с сырным бортиком сливочного сыра Филадельфия придает пицце больше нежности. Также у нас есть борт с сливочным сыром Филадельфия и ветчиной. При заказе пиццы с любым из бортиков – наш курьер привезет Вам восьмиугольную пиццу. Восьмиугольная пицца от Bella Mozzarella – первая пицца в Харькове выполненная в таком уникальном дизайне, которая мы уверены придётся Вам по вкусу.',
            text6:
              'Bella Mozzarella – это не только служба доставки еды в Харькове, но и семейная пиццерия. Bella Mozzarella — место, где отдыхает душа и тело. Место где можно отдохнуть самому, с семьей, с друзьями, с коллегами. Здесь Вас ждут блюда итальянской кухни, которые никого не оставят равнодушным. У нас можно посидеть не только внутри ресторана, а и на летней площадке. Наибольшей популярностью пользуется пицца. Это полюбившиеся местным жителям блюдо, плотно вошло в обиход. Ни один корпоратив, праздник не обходится без пиццы. В Bella Mozzarella можно не только посидеть и отдохнуть в самом заведении, можно организовать корпоратив, отметить абсолютно любой праздник. Круглосуточная доставка пиццы в Харькове, работает быстро, надежно и качественно, таким образом, сможете получить не только вкусовое наслаждение, но и созерцать первозданную красоту самого блюда. Bella Mozzarella — не просто семейная пиццерия, это место где всегда Вам рады и всегда Вас ждут. Вас встретит вежливый, радушный персонал, уютная атмосфера, вкусная еда, качественное обслуживание и полный релакс.',
            text7:
              'Доставка еды Харьков от Bella Mozzarella обеспечит вам комфорт, радость, ощущение праздника, порадует Вас быстрой доставкой, что позволит Вам всегда быть в хорошей форме и прекрасном настроении.',
            pizzaList1: 'с куриным филе;',
            pizzaList2: 'с грибами и куриным филе;',
            pizzaList3: 'с телятиной;',
            pizzaList4: 'с охотничьими колбасками;',
            pizzaList5: 'с ветчиной;',
            pizzaList6: 'с морепродуктами;',
            pizzaList7: 'с различными видами сыров.',
            shippingBenefits1: 'круглосуточная доставка еды в Харькове;',
            shippingBenefits2: 'доставка еды по Харькову — 59 минут;',
            shippingBenefits3:
              'удобная форма оплаты (наличная, по терминалу у курьера, Liqpay на сайте, безналичная в мобильном приложении);',
            shippingBenefits4: 'удобное мобильное приложение;',
            shippingBenefits5: 'регулярные акции;',
            shippingBenefits6: 'скидки на день рождения.',
            phone1: '+38 (093) 4321000',
            phone2: '+38 (098) 4321000',
            phone3: '+38 (099) 4321000',
          },
        },
      },
      dish: {
        sizeSmall: 'маленькая',
        sizeMedium: 'средняя',
        sizeBig: 'большая',
        liters: ' мл',
        grams: ' г',
        ingredientsDisplay: 'Удалить ингредиенты',
        side: 'Бортик',
        classicSide: 'Классический',
        cheeseSide: 'Сырный',
        baconSide: 'Сыр и бекон',
        add: 'Добавить в пиццу',
        addToCart: 'Добавить в корзину',
      },
      combo: {
        change: 'Изменить',
        board: 'Бортик: ',
        choseCombo: 'Выбрать комбо',
        addToCart: 'Добавить в корзину',
      },
      half: {
        choosePizzas: 'Выберите пиццы для левой и правой половинки',
        addToCart: 'Объеденить половинки',
        choose: 'Выберите',
        title: 'Выберите пиццу из половинок',
        left: 'левую половинку',
        right: 'правую половинку',
        weight: 'Вес ',
        gram: ' г',
      },
      cart: {
        components: {
          list: {
            subTitle: 'товара на',
            board: 'Бортик: ',
            additiveTitle: 'Добавить к заказу?',
          },
          bonus: {
            name: 'Узнать информацию о моих бонусах',
            enterPhone: 'Ввести телефон',
            title: 'Использовать бонусы',
            subTitle: 'У вас есть ',
            text1: '(Не более ',
            text2: ' % от заказа)',
          },
          promo: {
            title: 'Получите скидку',
            placeholder: 'Ввести промокод',
            text1: 'Промокод действует при минимальном заказe от ',
          },
          delivery: {
            title: 'Доставка и оплата',
            home: 'Доставка',
            cafe: 'Самовывоз',
            delivery: 'Доставка',
            pickup: 'Самовывоз',
            name: 'Имя*',
            phone: 'Телефон*',
            address: 'Адрес*',
            now: 'На сейчас',
            onTime: 'На время',
            time: 'Время',
            comment: 'Комментарий к заказу',
            meals: 'Количество приборов',
            nomeals: 'Приборы не нужны',
            required: '*Имя, телефон адрес обязательные к заполнению',
            address1: 'улица Сумская, 73 ',
            address2: 'Проспект Петра Григоренка, 18/1 ',
          },
          payment: {
            method: 'Выберите способ оплаты',
            paymentByCash: 'Наличными курьеру',
            paymentByCart: 'Через терминал',
            paymentByOnline: 'Картой онлайн',
          },
          liqpay: {
            goToPay: 'Перейти к оплате',
          },
        },
        title: 'Корзина',
        delivery: 'Доставка',
        freeDelivery: 'Бесплатная доставка от ',
        discount: 'Скидка: ',
        total: 'Сумма к оплате: ',
        checkoutButton: 'Оформить заказ на ',
        payButton: 'Оплатить ',
        emptyText: 'Ваша корзина пуста',
        doneTitle: 'Успех',
        doneText: 'Ваш заказ принят',
        doneText2: 'Номер заказа: ',
        donePaymentText: 'Ваш заказ принят! Теперь вы можете перейти к оплаты.',
      },
      terms: {
        title: 'Договор публичной оферты',
      },
    },
  },
};
